import React from 'react';
import LightHeader from './LightHeader';

const Header = () => {
  return (
    <div>
      <LightHeader></LightHeader>
    </div>
  );
};

export default Header;
